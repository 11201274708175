var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "big-badge",
    { attrs: { variant: _vm.setBadgeColor(_vm.status) } },
    [
      _vm._v(
        " " +
          _vm._s(_vm.$t(`payments.enumStatusContestation.${_vm.status}`)) +
          " "
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }