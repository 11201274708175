<template>
  <big-badge
    :variant="setBadgeColor(status)">
    {{ $t(`payments.enumStatusContestation.${status}`) }}
  </big-badge>
</template>

<script>
export default {
  name: 'BadgeContestation',
  props: {
    status: {
      type: String,
      required: true,
      default: ''
    }
  },
  components: {
    BigBadge: () => import('@components/shared/big-badge')
  },
  methods: {
    setBadgeColor (status){
      switch (status) {
        case 'OPEN':
          return 'warning'
        case 'FINISHED':
          return 'success'
        default:
          return 'default'
      }
    }
  }
    
}
</script>